<!--
 * @Author: SongYijie
 * @Date: 2020-03-09 09:42:27
 * @LastEditors: SongYijie
-->
<template>
  <div class="apply-user">
    <div class="action-wrap">
      <p class="label">地区</p>
      <div class="select" @click="handleShowSelect('areaOptions')">
        <p v-if="!areaOptions.selected.id">请选择 ></p>
        <p v-else class="selected">{{areaOptions.selected.label}}</p>
      </div>
    </div>
    <div class="action-wrap" @click="handleShowSelect('cardOptions')">
      <p class="label">证件类型</p>
      <div class="select">
        <p v-if="!cardOptions.selected.id">请选择 ></p>
        <p v-else class="selected">{{cardOptions.selected.label}}</p>
      </div>
    </div>
    <div class="action-wrap">
      <p class="label">真实姓名</p>
      <input placeholder="请填写真实姓名" v-model="name" maxlength="20" />
    </div>
    <div class="action-wrap">
      <p class="label">证件号码</p>
      <input placeholder="请填写证件号码" v-model="idNum" maxlength="20" />
    </div>
    <div class="action-wrap" @click="handleShowSelect('sexOptions')">
      <p class="label">性别</p>
      <div class="select">
        <p v-if="!sexOptions.selected.id">请选择 ></p>
        <p v-else class="selected">{{sexOptions.selected.label}}</p>
      </div>
    </div>
    <div class="action-wrap" @click="handleShowDateSelect">
      <p class="label">出生日期</p>
      <div class="select">
        <p v-if="!dateSelected">请选择 ></p>
        <p v-else class="selected">{{dateSelected}}</p>
      </div>
    </div>
    <p class="upload-info">请上传您的证件照片</p>
    <label class="upload" :for="'card'">
      <img src="@assets/images/user/bg-a.png" alt="upload" class="upload" v-if="!userIdcardImg1" />
      <img :src="userIdcardImg1 + '/watermark_text'" alt="upload" class="upload" v-else />
      <input
        type="file"
        class="file"
        accept="image/*"
        :ref="'inputRef'"
        :id="'card'"
        @change="handleUpload"
      />
    </label>
    <div class="footer-bt bg-white">
      <mt-button type="danger" size="large" @click="handleSubmit">提交</mt-button>
      <p class="warning">注意: 请使用本人有效证件进行认证!</p>
    </div>
    <mt-actionsheet :actions="areaOptions.data" v-model="areaOptions.visible"></mt-actionsheet>
    <mt-actionsheet :actions="cardOptions.data" v-model="cardOptions.visible"></mt-actionsheet>
    <mt-actionsheet :actions="sexOptions.data" v-model="sexOptions.visible"></mt-actionsheet>
    <mt-datetime-picker
      v-model="datetime"
      ref="datetimePicker"
      type="date"
      :endDate="new Date()"
      :startDate="new Date(1900,0,1)"
      @confirm="handleTimeConfirm"
    ></mt-datetime-picker>
  </div>
</template>

<script>
import { Indicator, Toast } from "mint-ui";
import { uploadCardImage, applyUserInfo } from "@api/user";
export default {
  components: {},
  data() {
    return {
      areaOptions: {
        selected: {
          id: "",
          label: "",
        },
        data: [
          {
            name: "中国香港",
            method: () => this.handleSelectArea({ id: 1, label: "中国香港" }),
          },
          {
            name: "中国澳门",
            method: () => this.handleSelectArea({ id: 2, label: "中国澳门" }),
          },
          {
            name: "中国台湾",
            method: () => this.handleSelectArea({ id: 3, label: "中国台湾" }),
          },
        ],
        visible: false,
      },
      cardOptions: {
        selected: {
          id: "",
          label: "",
        },
        data: [],
        visible: false,
      },
      sexOptions: {
        selected: {
          id: "",
          label: "",
        },
        data: [
          {
            name: "男",
            method: () =>
              this.handleSelected("sexOptions", { id: 1, label: "男" }),
          },
          {
            name: "女",
            method: () =>
              this.handleSelected("sexOptions", { id: 2, label: "女" }),
          },
        ],
        visible: false,
      },
      dateSelected: "",
      datetime: new Date(2000, 0, 1),
      name: "",
      idNum: "",
      userIdcardImg1: "",
    };
  },
  methods: {
    handleShowSelect(type) {
      this[type].visible = true;
    },
    handleSelectArea(value) {
      this.areaOptions.selected = value;
      const { id } = value;
      if (id === 1) {
        this.cardOptions.data = [
          {
            name: "香港居民来往内地通行证",
            method: () =>
              this.handleSelected("cardOptions", {
                id: 2,
                label: "香港居民来往内地通行证",
              }),
          },
          {
            name: "香港居民居住证",
            method: () =>
              this.handleSelected("cardOptions", {
                id: 3,
                label: "香港居民居住证",
              }),
          },
        ];
        this.cardOptions.selected = {
          id: 2,
          label: "香港居民来往内地通行证",
        };
      } else if (id === 2) {
        this.cardOptions.data = [
          {
            name: "澳门居民来往内地通行证",
            method: () =>
              this.handleSelected("cardOptions", {
                id: 6,
                label: "澳门居民来往内地通行证",
              }),
          },
          {
            name: "澳门居民居住证",
            method: () =>
              this.handleSelected("cardOptions", {
                id: 7,
                label: "澳门居民居住证",
              }),
          },
        ];
        this.cardOptions.selected = {
          id: 6,
          label: "澳门居民来往内地通行证",
        };
      } else {
        this.cardOptions.data = [
          {
            name: "台湾居民往来大陆通行证",
            method: () =>
              this.handleSelected("cardOptions", {
                id: 4,
                label: "台湾居民往来大陆通行证",
              }),
          },
          {
            name: "台湾居民居住证",
            method: () =>
              this.handleSelected("cardOptions", {
                id: 5,
                label: "台湾居民居住证",
              }),
          },
        ];
        this.cardOptions.selected = {
          id: 4,
          label: "台湾居民往来大陆通行证",
        };
      }
    },
    handleSelected(type, value) {
      this[type].selected = value;
    },
    handleShowDateSelect() {
      this.$refs.datetimePicker.open();
    },
    handleTimeConfirm(e) {
      this.dateSelected = `${e.getFullYear()}-${
        e.getMonth() + 1
      }-${e.getDate()}`;
    },
    handleUpload(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      Indicator.open("证件上传中...");
      let timer = setTimeout(() => {
        Indicator.close();
      }, 20000);
      let imageFormData = new FormData();
      imageFormData.append("uploadImage", file);
      this.$refs.inputRef.value = "";
      uploadCardImage(imageFormData).then((res) => {
        Indicator.close();
        if (res.code === 200) {
          clearTimeout(timer);
          this.userIdcardImg1 = res.data;
        } else {
          Toast(res.message);
        }
      });
    },
    handleSubmit() {
      const {
        name,
        idNum,
        areaOptions,
        cardOptions,
        sexOptions,
        dateSelected,
        userIdcardImg1,
      } = this;
      if (
        name &&
        idNum &&
        areaOptions.selected.id &&
        cardOptions.selected.id &&
        sexOptions.selected.id &&
        dateSelected &&
        userIdcardImg1
      ) {
        const data = {
          userName: name,
          idNum,
          area: areaOptions.selected.id,
          idCardType: cardOptions.selected.id,
          userSex: sexOptions.selected.id,
          userBirthday: dateSelected,
          userIdcardImg1,
        };
        applyUserInfo(data).then((res) => {
          if (res.code === 200) {
            this.$router.replace("/user/confirmApplyUser");
          } else if (res.code === 3063) {
            let instance = Toast("您的实名信息正在审核中, 请勿重复提交");
            setTimeout(() => {
              instance.close();
              this.$router.replace("/user/confirmApplyUser");
            }, 2000);
          } else if (res.code === 3064) {
            let instance = Toast("您的实名信息已经审核通过, 无需重复提交");
            setTimeout(() => {
              instance.close();
              this.$router.replace("/taskCenter");
            }, 2000);
          } else {
            Toast(res.message);
          }
        });
      } else {
        Toast("请填写完整的信息");
      }
    },
  },
};
</script>
<style lang='less' scoped>
* {
  box-sizing: border-box;
  margin: 0;
}
.apply-user {
  background-color: #ffffff;
  padding: 3.2vw;
  padding-bottom: 26.6667vw;
}
.action-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 13.3333vw;
  border-bottom: 0.1333vw solid rgb(240, 240, 240);
}
input {
  width: 60%;
  font-size: 4.2667vw;
  text-align: right;
  border: none;
}
input::-webkit-input-placeholder {
  font-size: 3.4667vw;
  color: rgba(137, 137, 137, 0.6);
}
input::-moz-placeholder {
  font-size: 3.4667vw;
  color: rgba(137, 137, 137, 0.6);
}
input::-ms-input-placeholder {
  font-size: 3.4667vw;
  color: rgba(137, 137, 137, 0.6);
}
.select {
  text-align: right;
  width: 60%;
  font-size: 3.4667vw;
  color: rgba(137, 137, 137, 0.6);
}
.selected {
  font-size: 4.6vw;
  color: #666666;
}
.label {
  width: 40%;
  color: rgb(137, 137, 137);
  font-size: 4.2667vw;
}
.upload-info {
  height: 13.3333vw;
  line-height: 13.3333vw;
  background-color: rgb(250, 250, 250);
  margin: 0 -3.2vw;
  padding: 0 3.2vw;
}
.upload {
  width: 82.9333vw / 1.2;
  height: 49.3333vw / 1.2;
  display: block;
  margin: 3.2vw auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.warning {
  font-size: 3.2vw;
  color: rgba(168, 168, 168, 0.6);
}
.file {
  display: none;
}
</style>
